import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/ca';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Table, Row, Col, Pagination, Input, Space, Button, Modal, Select, Form, Tooltip, Popconfirm, Progress } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';
import { Api, HtmlStrings } from '../../../helpers';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';
const dateRangeFormat = 'DD/MM/YYYY';

function RollCallAlumne(props) {

  const [id_curs_escolar, setId_curs_escolar] = useState(5);
  const [numDiesTotal, setNumDiesTotal] = useState(0);
  const [id_alumne, setId_alumne] = useState(0);
  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [numRollCalls, setNumRollCalls] = useState(0);
  const [totalRollCalls, setTotalRollCalls] = useState(200);
  const [diesNotPresent, setDiesNotPresent] = useState(0);
  const [rollCallAlumne, setRollCallAlumne] = useState([]);

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    buscarDadesAlumnes();
  },[]);

  useEffect(() => {
    buscarDiesRollCalls();
  },[]);


  useEffect(() => {
    if(id_alumne > 0){
      buscarNotPresent();
      buscarRollCallAlumne();
    }
  },[id_alumne]);



  const triaAlumne = (value) =>{
    setId_alumne(value);
  }

  const buscarDadesAlumnes = async () => {

    const alumnes = await Api.get(`/alumnes`);
    if(alumnes.result.success===1){
      const llista_alumnes =alumnes.data.alumnes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id +' - '+ entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2) + ' - ' + entities.decode(item.classe)}
              </Select.Option>
            );
      });
      setAlumnesOptions(llista_alumnes);
    }
  }

  const buscarDiesRollCalls = async () => {
    const roll = await Api.get(`/dies/rollcall/${id_curs_escolar}`);
    if(roll.result.success===1){
      setNumRollCalls(roll.data.actual);
      setTotalRollCalls(roll.data.total);
    }
  }

const buscarNotPresent = async () => {
  const not = await Api.get(`/dies/rollcall/notpresent/${id_alumne}/${id_curs_escolar}`)
  if(not.result.success===1){
    setDiesNotPresent(not.data.alumne.not_present);
  }
}

const buscarRollCallAlumne = async () => {
  setIsLoading(true);
  const not = await Api.get(`/dies/rollcall/all/${id_alumne}/${id_curs_escolar}`)
  if(not.result.success===1){
    setRollCallAlumne(not.data.alumne);
  }
  setIsLoading(false);
}

const columnes =[
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title:"Id_rollcall",
    key:"id_rollcall",
    sorter: (a, b) =>  a.id_rollcall - b.id_rollcall,
    render: (text,record) => (<span>{record.id_rollcall}</span>)
  },{
    title: props.t('generic.data'),
    key:"dia",
    sorter: (a, b) => moment(a.dia).diff(moment(b.dia)),
    render: (text,record) =>(record.dia ? moment(record.dia).format('DD/MM/YYYY') : '')
  },{
    title: props.t('passar_llista.assistencia'),
    key:"present",
    sorter: (a, b) =>  a.present - b.present,
    render: (text,record) => (<span>{record.present === 0 ? props.t('generic.no') : props.t('generic.si')}</span>)
  },{
    title: props.t('passar_llista.motiu'),
    key: "motiu_absencia",
    render: (text,record) =>(entities.decode(record.motiu_absencia)),
    //filters: filtreTipus,
    //onFilter: (value, record) => (record.motiu_absencia === value),
    sorter: (a, b) => { return entities.decode(a.motiu_absencia).localeCompare(entities.decode(b.motiu_absencia))},
  },{
    title: props.t('passar_llista.observacions'),
    key: "boservacions",
    render: (text,record) =>(entities.decode(record.boservacions)),
    sorter: (a, b) => { return entities.decode(a.boservacions).localeCompare(entities.decode(b.boservacions))},
  },{
    title: props.t('passar_llista.observacions'),
    key: "boservacions",
    render: (text,record) =>(entities.decode(record.boservacions)),
    sorter: (a, b) => { return entities.decode(a.boservacions).localeCompare(entities.decode(b.boservacions))},
  },{
    title: props.t('menu.Professors'),
    key: "profe",
    render: (text,record) =>(entities.decode(record.profe)),
    sorter: (a, b) => { return entities.decode(a.profe).localeCompare(entities.decode(b.profe))},
  },{
    title: props.t('generic.created_at'),
    key:"created_at",
    sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
    responsive:['md'],
    render:(text,record) => (record.created_at ? <Moment date={moment(record.created_at)} format="DD/MM/YYYY HH:mm"/> : "" )
  },{
    title: props.t('generic.created_by'),
    key: "created_by_name",
    render: (text,record) =>(entities.decode(record.created_by_name)),
    sorter: (a, b) => { return entities.decode(a.created_by_name).localeCompare(entities.decode(b.created_by_name))},
  },{
    title: props.t('generic.updated_at'),
    key:"updated_at",
    sorter: (a, b) => moment(a.updated_at).diff(moment(b.updated_at)),
    responsive:['md'],
    render:(text,record) => (record.updated_at ? <Moment date={moment(record.updated_at)} format="DD/MM/YYYY HH:mm"/> : "" )
  },{
    title: "Updated by",
    key: "updated_by_name",
    render: (text,record) =>(entities.decode(record.updated_by_name)),
    sorter: (a, b) => { return entities.decode(a.updated_by_name).localeCompare(entities.decode(b.updated_by_name))},
  }];


return(
  <>
    <TitolPagina txt={props.t('menu.AssistenciaAlumne')} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
    <div style={{marginTop:'1rem', marginLeft:'1rem', marginRight: '1rem', padding: '1rem'}}>
      <Row>

      <Col span={6} offset={3}>
        <Row align='center'>
          <Progress type="circle" percent={numRollCalls/totalRollCalls*100} format={(percent) => `${numRollCalls} Days`} />
        </Row>
        <Row align='center'>
          <>Total of RollCalls this school year. {totalRollCalls}</>
        </Row>
      </Col>
      {id_alumne === 0 ? null :
        <>
        <Col span={6}>
          <Row align='center'>
            <Progress type="circle" percent={diesNotPresent===0 ? 100 : (diesNotPresent/numRollCalls*100).toFixed(2)}/>
          </Row>
          <Row align='center' style={{marginBottom: 0}}>
            <>Not present days:  {diesNotPresent} of {numRollCalls}</>
            <br/>
          </Row>
          <Row align='center' style={{margin: 0}}>
            <>No es tenen en compte els motius: cita medica, tard, surt abans</>
          </Row>
        </Col>
        <Col span={6}>
          <Row align='center'>
            <div style={{width: 170,}}>
              <Progress percent={30} size="small" />
              <Progress percent={50} size="small" status="active" />
              <Progress percent={70} size="small" status="exception" />
              <Progress percent={100} size="small" />
            </div>
          </Row>

          <Row align='center' style={{marginBottom: 0}}>
            <>Not present days:  {diesNotPresent} of {numRollCalls}</>
            <br/>
          </Row>
          <Row align='center' style={{margin: 0}}>
            <>No es tenen en compte els motius: cita medica, tard, surt abans</>
          </Row>
        </Col>
        </>
      }

    </Row>
      <Row>
        <Col span={6} style={{paddingLeft: '0.5rem'}}>
          <Form.Item label={props.t('generic.alumne')} required={true} style={{marginBottom: 0}}>
            <Select
              value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
              showSearch
              filterOption={(input, option) =>
                HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
              }>
              {alumnesOptions}
            </Select>
          </Form.Item>
        </Col>
        {id_alumne===0 ? null :
          <Table
            loading={isLoading}
            pagination={

              {showSizeChanger: true, defaultPageSize: 25, pageSizeOptions:[25,100,500,1000], position: ['topRight', 'bottomRight']}
            }
            rowKey="id"
            dataSource={rollCallAlumne}
            locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
            columns={columnes}
          />
        }
      </Row>
    </div>


</>
);

}
export default translate(RollCallAlumne);
