import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ClearOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, AlertTwoTone } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesSummerExterns';
import ComandesSummerExternsExportaExcelCursEscolar3 from '../../../components/Formularis/perfils/admin/ComandesSummerExternsExportaExcelCursEscolar3';
import ComandesSummerExternsExportaExcelCursEscolar4 from '../../../components/Formularis/perfils/admin/ComandesSummerExternsExportaExcelCursEscolar4';
import ComandesSummerExternsExportaExcelCursEscolar from '../../../components/Formularis/perfils/admin/ComandesSummerExternsExportaExcel';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function ComandesSummerExterns(props){
  moment.locale('es');
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);

  const [triats, setTriats] = useState([]);
  const [modificarEstatTriatsLoading, setModificarEstatTriatsLoading] = useState(false);
  const [modalEstatVisible, setModalEstatVisible] = useState(false);
  const [estatsOptions, setEstatsOptions] = useState([]);
  const [estatTriat, setEstatTriat] = useState('PENDIENTE PAGO');

  const [refrescar, setRefrescar] = useState(0);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA



  const buscarDades = async () => {
    //const com = await Api.get(`/summer/extern/comandes`);
    const com = await Api.get(`/summer/extern/comandes/${cursEscolarTriat}`);
    if(com.result.success===1){
      //console.log(com.data)
      return com.data;
    }

  }


  const marcarEliminat = async (id) => {
  const c = await Api.myFetch(`/summer/${id}`, 'DELETE');
    if(c.result.success===1){
      setRefrescar(refrescar+1)
    }
  }

  const modificarEstatTriats = async () =>{

      setModificarEstatTriatsLoading(true);

      let dades = {
        estat: estatTriat,
        ids_comandes: triats
      }

      try{
        //  console.log(JSON.stringify(dades))
        const resposta = await Api.myFetch(`/summer/extern/estat/${estatTriat}`, 'PUT', JSON.stringify(dades));
        if(resposta.result.success === 1){
          setTriats([]);
          setEstatTriat('PENDIENTE PAGO');
          setModificarEstatTriatsLoading(false);
          setModalEstatVisible(false);
          setRefrescar(refrescar+1);
        }else{
          setModificarEstatTriatsLoading(false);
          console.error('Error: submit summer extern estat: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
          return false;
        }

      }catch(error){
        setModificarEstatTriatsLoading(false);
        message.config({top: 50});
        message.open({content: "Error saving state", duration:10});
        console.error('Error: summer extern guardar estat: ', error);
        return false;
      }

    }


  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA



  const filtreEstat =[
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Pendent')}`}}/>&nbsp;PENDIENTE PAGO</span>, value: 'PENDIENTE PAGO'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Preparat')}`}}/>&nbsp;TPV revisar</span>, value: 'TPV revisar'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Facturat')}`}}/>&nbsp;{props.t('botiga.estat.Facturat')}</span>, value: 'Facturat'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Anulat')}`}}/>&nbsp;{props.t('botiga.estat.Anulat')}</span>, value: 'Anulat'}
  ];


  const columns = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>),
    width: 10
  },{
    title:"DS_Order",
    key:"ds_order",
    sorter: (a, b) =>  a.ds_order - b.ds_order,
    render: (text,record) => (<span>{record.ds_order}</span>),
    width: 10
  },{
    /*title:"DS_Response",
    key:"ds_response",
    sorter: (a, b) =>  a.ds_response - b.ds_response,
    render: (text,record) => (<span>{record.ds_response}</span>),
    width: 10
  },{*/
    title:"DS_auth_code",
    key:"ds_authorisationcode",
    sorter: (a, b) =>  a.ds_authorisationcode - b.ds_authorisationcode,
    render: (text,record) => (<span>{record.ds_authorisationcode}</span>),
    width: 10
  },{
    title: props.t('botiga.titolEstat'),
    key:"estado",
    dataIndex: 'estado',
    filters: filtreEstat,
    defaultFilteredValue: ['PENDIENTE PAGO','TPV revisar'],
    onFilter: (value, record) => record.estado === value,
    render: (text,record) =>{

        switch (record.estado) {
          case 'PENDIENTE PAGO':
            return (<span><AlertFilled style={{color: `${props.t('botiga.estatColor.Pendent')}`}}/>&nbsp;{record.estado}</span>);
            break;
          case 'TPV revisar':
            return (<span><AlertFilled style={{color: `${props.t('botiga.estatColor.Preparat')}`}}/>&nbsp;{record.estado}</span>);
            break;
          case 'Facturat':
            return (<span><AlertFilled style={{color: `${props.t('botiga.estatColor.Facturat')}`}}/>&nbsp;{record.estado}</span>);
            break;
          case 'Anulat':
            return (<span><AlertFilled style={{color: `${props.t('botiga.estatColor.Anulat')}`}}/>&nbsp;{record.estado}</span>);
            break;


          default:
            return (<span>{record.estado}</span>);
        }

    },
    sorter: (a, b) => { return a.estado.localeCompare(b.estado)}
  },{
    title: props.t('generic.alumne'),
    key:"json.nens[0].nom",
    dataIndex: 'json.nens[0].nom',
    //render: (text,record) =>(entities.decode(record.json.pare.nom+' '+record.json.pare.cognoms))
    //render: (text,record) =>(<span>{record.json.nens[0].nom+' '+record.json.nens[0].cognoms}</span>),
    sorter: (a, b) => {
      console.log('sorter a: ', a.json.nens[0].alumne_complet)
      return (a.json.nens[0].nom+' '+a.json.nens[0].cognoms).localeCompare(b.json.nens[0].nom+' '+b.json.nens[0].cognoms)
    },
    render: (text,record) =>(<span>{record.json.nens[0].nom+' '+record.json.nens[0].cognoms}</span>)
  },{
    title:"Num Alumnes",
    key:"json.nens.length",
    sorter: (a, b) =>  a.json.nens.length - b.json.nens.length,
    render: (text,record) => (<span>{record.json.nens.length}</span>)
  },{
    title: "Pare",
    key:"json.pare.nom",
    dataIndex: 'json.pare.nom',
    //render: (text,record) =>(entities.decode(record.json.pare.nom+' '+record.json.pare.cognoms))
    render: (text,record) =>(<span>{record.json.pare.nom+' '+record.json.pare.cognoms}</span>)
  },{
    title:props.t('generic.total'),
    key:"total_absol",
    dataIndex: 'total_absol',
    render: (text,record) => (<span>{record.total_absol}€</span>),
    sorter: (a, b) =>  a.total - b.total
  },{
    title: 'Pagat',
    key:"ds_amount",
    dataIndex: 'ds_amount',
    render: (text,record) => (<span>{record.ds_amount/100}€</span>),
    sorter: (a, b) =>  a.ds_amount - b.ds_amount
  },{
    title:props.t('generic.created_at'),
    key:"fcrea",
    sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
    //render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
    render:(text,record) => (record.fcrea ? <Moment date={moment(record.fcrea).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
  },{
    title:'Email enviat',
    key:"email_enviat",
    sorter: (a, b) => moment(a.email_enviat).diff(moment(b.email_enviat)),
    //render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
    render:(text,record) => (record.email_enviat ? <Moment date={moment(record.email_enviat).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
  }];


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.ComandesSummerCamp')+' EXT'}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={props.t('comandes.creados')}
          txt_nou={props.t('comandes.nuevo')}
          modal_width={650}
          buscarDades={buscarDades}
          dadesMostradesCallback={(dadesMostrades) => {

            switch (cursEscolarTriat) {
              case 3:
                return(
                  <div style={{marginTop: '1rem'}}>
                    <ComandesSummerExternsExportaExcelCursEscolar3 dades={dadesMostrades} btn_txt="Export"/>
                  </div>
                )
                break;
              case 4:
                return(
                  <div style={{marginTop: '1rem'}}>
                    <ComandesSummerExternsExportaExcelCursEscolar4 dades={dadesMostrades} btn_txt="Export"/>
                  </div>
                )
                break;
              case 5:
                return(
                  <div style={{marginTop: '1rem'}}>
                    <ComandesSummerExternsExportaExcelCursEscolar dades={dadesMostrades} btn_txt="Export"/>
                  </div>
                )
                break;
              default:
                return(<div></div>)
            }


          }}
          btn_extra={
            <>
              <TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>
              <Button type="default" disabled={!triats.length > 0 } onClick={()=>setModalEstatVisible(true)}>{props.t('comandes.modificar_estat')}</Button>
              <Tooltip placement="top" title={props.t('comandes.triats_netejar')}>
                <Button type="default" disabled={!triats.length > 0 }  icon={<ClearOutlined/>} onClick={()=>setTriats([])}></Button>
              </Tooltip>
            </>
          }
          rowSelection={{
            selectedRowKeys: triats,
            hideSelectAll: false,
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setTriats(selectedRowKeys);
            }
          }}
          refrescar={refrescar}
          edit={Edit}
          //add={Edit}
          id_curs_escolar={cursEscolarTriat}
          duplica={false}
          delete={marcarEliminat}
          //itemBuit={InitDades.comandes_roba()}
          columns={columns}
          icon_creats={InboxOutlined}
        />

      </div>
      <Modal
        title={props.t('comandes.modificar_estat')}
        destroyOnClose={true}
        visible={modalEstatVisible}
        onOk={modificarEstatTriats}
        confirmLoading={modificarEstatTriatsLoading}
        onCancel={()=>setModalEstatVisible(false)}
      >
        <p>{props.t('comandes.modificar_estat_comandes')}</p>
        <Form.Item label={props.t('botiga.titolEstat')}>
          <Select onChange={(value) => setEstatTriat(value)} defaultValue="PENDIENTE PAGO">
            <Select.Option key={'PENDIENTE PAGO'} value={'PENDIENTE PAGO'}>
              <span><AlertFilled style={{color: 'red'}}/>&nbsp;PENDIENTE PAGO</span>
            </Select.Option>
            <Select.Option key={'TPV revisar'} value={'TPV revisar'}>
              <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Preparat')}`}}/>&nbsp;TPV revisar</span>
            </Select.Option>
            <Select.Option key={'Facturat'} value={'Facturat'}>
              <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Facturat')}`}}/>&nbsp;{props.t('botiga.estat.Facturat')}</span>
            </Select.Option>
            <Select.Option key={'Anulat'} value={'Anulat'}>
              <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Anulat')}`}}/>&nbsp;{props.t('botiga.estat.Anulat')}</span>
            </Select.Option>
          </Select>
        </Form.Item>
      </Modal>

    </ContainerPagina>
  );
}


export default translate(ComandesSummerExterns);
