import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [nursery, setNursery] = useState([]);
  const [reception, setReception] = useState([]);
  const [year1, setYear1] = useState([]);
  const [year2, setYear2] = useState([]);
  const [year34, setYear34] = useState([]);
  const [year56, setYear56] = useState([]);
  const [year78, setYear78] = useState([]);

  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesSummerCamp');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesSummerCamp');

  useEffect(() => {

    let fnursery_temp = [];
    let freception_temp = [];
    let fyear1_temp = [];
    let fyear2_temp = [];
    let fyear34_temp = [];
    let fyear56_temp = [];
    //let fyear78_temp = [];
    console.log('props.dades', props.dades)

    props.dades.forEach((comanda, i) => {

      if(comanda.estado === 'PENDIENTE PAGO'){
        return true;
      }
      comanda.json.nens.forEach((nen, i) => {
        nen.id_comanda = comanda.id;
        nen.estat = comanda.estado;
        nen.creat = comanda.fcrea;
        nen.alumne_complet = nen.nom+' '+nen.cognoms;
        nen.pare = comanda.json.pare;
        nen.pare2 = comanda.json.pare2;

        for(let i=1; i<2500; i++ ){
          nen[`w${i}`] = 0;
        }

        if(nen.comprat && nen.comprat.propietats.weeks.w1 > 0){
          const p = nen.comprat.propietats.weeks.w1;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w2 > 0){
          const p = nen.comprat.propietats.weeks.w2;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w3 > 0){
          const p = nen.comprat.propietats.weeks.w3;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w4 > 0){
          const p = nen.comprat.propietats.weeks.w4;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w5 > 0){
          const p = nen.comprat.propietats.weeks.w5;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b1 > 0){
          const p = nen.comprat.propietats.bus.b1;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b2 > 0){
          const p = nen.comprat.propietats.bus.b2;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b3 > 0){
          const p = nen.comprat.propietats.bus.b3;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b4 > 0){
          const p = nen.comprat.propietats.bus.b4;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b5 > 0){
          const p = nen.comprat.propietats.bus.b5;
          nen[`w${p}`] = 1;
        }

        if(nen.comprat){
          switch (nen.comprat.id_producte) {
            case 263:
              fnursery_temp.push(nen);
              break;
            case 264:
              freception_temp.push(nen);
              break;
            case 265:
              fyear1_temp.push(nen);
              break;
            case 266:
              fyear2_temp.push(nen);
              break;
            case 267:
              fyear34_temp.push(nen);
              break;
            case 268:
              fyear56_temp.push(nen);
              break;
/*            case 215:
              fyear78_temp.push(nen);
              break;
*/
            default:
          }
        }
      });

    });

    setNursery(fnursery_temp);
    setReception(freception_temp);
    setYear1(fyear1_temp);
    setYear2(fyear2_temp);
    setYear34(fyear34_temp);
    setYear56(fyear56_temp);
  //  setYear78(fyear78_temp);

  },[props.dades]);




  return (
    <ExcelFile
      filename={nom_fitxer}
      element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
        <ExcelSheet data={nursery} name={'Nursery'}>
          <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
          <ExcelColumn label="Id_fill" value="id"/>
          <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
          <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
          <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
          <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
          <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

          <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2048"/>
          <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w2049"/>
          <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
          <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
          <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

          <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w2053"/>
          <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w2054"/>
          <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
          <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
          <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

          <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w2058"/>
          <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w2059"/>
          <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
          <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
          <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

          <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w2063"/>
          <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w2064"/>
          <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
          <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
          <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

          <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2068"/>
          <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2069"/>
          <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
          <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
          <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>

          </ExcelSheet>

          <ExcelSheet data={reception} name={'Reception'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2079"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w2080"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w2084"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w2085"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w2089"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w2090"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w2094"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w2095"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2099"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2100"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year1} name={'Year 1'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2110"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w2111"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w2115"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w2116"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w2120"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w2121"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w2125"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w2126"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2130"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2131"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year2} name={'Year 2'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2303"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w2141"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w2145"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w2146"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

            <ExcelColumn label={'Setmana 3: Sailing 9h-16h'} value="w2151"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w2155"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w2156"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2160"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2161"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year34} name={'Year 3,4'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2172"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w2173"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w2177"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w2178"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w2186"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w2182"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

            <ExcelColumn label={'Setmana 4: Sailing 9h-16h'} value="w2187"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2191"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2192"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year56} name={'Year 5,6'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w2203"/>
            <ExcelColumn label={'Setmana 1: 9h a 16h (Dinar)'} value="w2204"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w2309"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w2314"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w2304"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w2208"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w2310"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w2315"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w2305"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w2212"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w2213"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w2311"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w2316"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w2306"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w2217"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w2218"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w2312"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w2317"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w2307"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w2222"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w2223"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w2313"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w2318"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w2308"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>
          {/*<ExcelSheet data={year78} name={'Year 7,8'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: Trekking 9h a 16h (Dinar)'} value="w1622"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1623"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1624"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1625"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w1627"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1628"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1629"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1630"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1631"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w1632"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1633"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1634"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1635"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1636"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w1637"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1638"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1639"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1640"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1641"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1642"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1643"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1644"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1645"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>


            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>

            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>*/}
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
